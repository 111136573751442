* {
  margin: 0px;
}

:root {
  --grayRgb    : rgba(45, 45, 45, .95);
  --grayHex    : #4a4a4a;
  --lighGrayRgb: rgb(74, 74, 74);
}

.main-header:before {
  background: linear-gradient(54deg, var(--grayRgb) 34%, var(--grayRgb));
  height    : 100%;
  left      : 0;
  right     : 0;
  position  : absolute;
  content   : "";
  z-index   : 0;
}

.menu.menu--active .menu__wrapper a:hover {
  color: var(--lighGrayRgb) !important;
}

.menu.menu--active .menu__wrapper a {
  opacity: 1;
  color  : rgb(85, 85, 85) !important;
}

.background {
  background-color: var(--grayHex);
}

.background:before {
  background: linear-gradient(54deg, var(--grayRgb) 34%, var(--grayRgb));
}

.slider-carousel .slider-carousel__circle {
  color: var(--grayHex);
}

.site-btn {
  background-color: var(--lighGrayRgb);
}

.site-btn.site-btn:hover {
  background-color: #575757 !important;
}

.contacts .contacts__social a:hover {
  color: var(--grayRgb);
}

.progress-list .progress .progress-bar {
  background-color: var(--lighGrayRgb);
}

.resume-list .resume-list_title {
  color: rgb(153, 153, 153);
}

.resume-list .resume-list__block-title {
  color: var(--lighGrayRgb);
}

.resume-list .resume-list__block {
  border-left: 2px solid var(--lighGrayRgb);
}

.resume-list .resume-list__block::before {
  border: 2px solid var(--lighGrayRgb);
}

.contacts__display {
  display        : flex;
  justify-content: space-between;
}

.contacts__social {
  flex: 0.4;
}

.contacts__qrcode {
  flex: 0.6;
}

.contacts__qrcode img {
  width: 50%
}

.contacts__qrcode p {
  font-size  : 13px;
  line-height: 1.5em;
  width      : 50%;
  margin-top : 5px;
}